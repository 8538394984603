import formatISODuration from 'date-fns/formatISODuration';
import React, { Component } from 'react';

interface Props {
  seconds: number;
}

export default class Duration extends Component<Props> {
  render() {
    return (
      <time
        className="duration"
        dateTime={Duration.formatDurationForMachines(this.props.seconds)}
      >
        {Duration.formatDurationForHumans(this.props.seconds)}
      </time>
    );
  }

  static formatDurationForMachines(seconds) {
    return formatISODuration({ seconds });
  }

  static formatDurationForHumans(totalSeconds) {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds - minutes * 60;
    const minutesString = minutes > 9 ? `${minutes}` : `0${minutes}`;
    const secondsString = seconds > 9 ? `${seconds}` : `0${seconds}`;
    return `${minutesString}:${secondsString}`;
  }
}

import _ from 'lodash';

class HorizontalLayoutHelper {
  constructor(
    stockItems,
    width,
    rowHeight,
    maxRemainingSpace,
    hideLastRowIfIncomplete,
    minWidthPixels,
    layoutUpdateCallbackMethod
  ) {
    this.totalHeight = 0;
    this.blockCount = 0;
    this.workingRow = [];
    this.grid = [];
    this.stockItems = stockItems;
    this.positions = {};
    this.layoutWidth = width;
    this.height = rowHeight;
    this.workingWidth = width;
    this.threshold = maxRemainingSpace;
    this.hideLastRow = hideLastRowIfIncomplete;
    this.minWidth = minWidthPixels;
    this.drawerIsLoading = false;
    this.drawerIsOpen = false;
    this.drawerItemCount = 0;
    this.selectedBlockIndex = -1;
    this.itemsPerRow = 0;
    this.layoutUpdateCallback = layoutUpdateCallbackMethod || _.noop;
  }

  setDrawerClosed() {
    this.drawerIsLoading = false;
    this.drawerIsOpen = false;
    this.selectedBlockIndex = -1;
  }

  setDrawerLoading(selectedBlockIndex) {
    this.drawerIsLoading = true;
    this.drawerIsOpen = true;
    if (selectedBlockIndex >= 0) {
      this.selectedBlockIndex = selectedBlockIndex;
    } else {
      console.error(
        `Cannot set drawer as open with a selected block index of ${selectedBlockIndex}`
      );
    }
  }

  setDrawerPopulated(selectedBlockIndex, itemCount, itemsPerRow) {
    if (selectedBlockIndex >= 0) {
      this.selectedBlockIndex = selectedBlockIndex;
    } else {
      console.error(
        `Cannot set drawer as open with a selected block index of ${selectedBlockIndex}`
      );
    }
    this.drawerIsLoading = false;
    this.drawerIsOpen = true;
    this.drawerItemCount = itemCount;
    this.itemsPerRow = itemsPerRow;
  }

  compute() {
    _.forEach(this.stockItems, (n) => {
      if (!('aspectRatio' in n.stockItem)) {
        console.error(
          'Objects passed to layoutBlocks must contain aspectRatio parameter'
        );
        return true; // Skip this stock item
      }

      const block = {
        id: n.stockItem.id,
        aspectRatio: n.stockItem.aspectRatio || 1,
        noAspectRatio: n.stockItem.aspectRatio == null,
      };

      this._addBlock(block);
    });

    if (this.workingRow.length > 0) {
      this._finishRow();
    }

    let drawerPosition = {};
    if (this.drawerIsOpen) {
      const rowForDrawer = 1 + this.getContainingRow(this.selectedBlockIndex);
      const drawerY = this.getTotalHeightOfPrecedingRows(rowForDrawer);
      // The Drawer should be full-width so we just need to set its X and Y coordinates.
      drawerPosition = {
        width: this.layoutWidth,
        x: 0,
        y: drawerY,
      };
    }

    this.layoutUpdateCallback(this.positions, this.totalHeight, drawerPosition);
  }

  getContainingRow(blockIndex) {
    if (blockIndex > this.blockCount) {
      // Don't try to find the row of a block that isn't in the grid yet.
      return -1;
    }
    let totalBlocks = 0;
    for (let row = 0; row < this.grid.length; row++) {
      totalBlocks += this.grid[row].length;
      if (totalBlocks > blockIndex) {
        return row;
      }
    }
    // Return an invalid index if the block isn't in the grid yet.
    return -1;
  }

  getLastBlockIndexInContainingRow(blockIndex) {
    let lastInRow = 0;
    for (let row = 0; row < this.grid.length; row++) {
      lastInRow += this.grid[row].length - 1;
      if (lastInRow >= blockIndex) {
        break;
      }
    }
    return lastInRow;
  }

  getTotalHeightOfPrecedingRows(rowIndex) {
    return this.grid
      .slice(0, rowIndex)
      .reduce(
        (sum, row) => (sum += Math.floor(this.positions[row[0].id].height)),
        0
      );
  }

  getXMidpointForBlockById(blockId) {
    if (!blockId) {
      return null;
    }
    const wrapper = this.positions[blockId];
    return wrapper.x + Math.floor(wrapper.width / 2);
  }

  _addBlock(block) {
    this.blockCount += 1;
    const blockWidth = this._getBlockWidth(block, this.height);

    // No problems, block fits
    if (blockWidth < this.workingWidth) {
      this._addBlockToWorkingRow(block, blockWidth);
    }
    // Block is too big AND there's too much room left to finish the row
    else if (
      blockWidth > this.workingWidth &&
      this.workingWidth > this.threshold
    ) {
      this._addBlockToWorkingRow(block, blockWidth);
      this._finishRow();
    }
    // Block is too big but little enough room left to finish row
    else if (
      blockWidth > this.workingWidth &&
      this.workingWidth <= this.threshold
    ) {
      // Finish the row and add block to next row
      this._finishRow();
      this._addBlockToWorkingRow(block, blockWidth);
    } else {
      this._finishRow(block, blockWidth);
    }
  }

  _finishRow(block, blockWidth) {
    if (block) {
      this._addBlockToWorkingRow(block, blockWidth);
    }

    this._scaleWorkingRow();
    this.grid.push(this.workingRow);
    this._resetWorkingRow();
    this._accountForDrawer();
  }

  _addBlockToWorkingRow(blockObj, blockWidth) {
    this.workingRow.push(blockObj);
    this.workingWidth -= blockWidth;
  }

  _getBlockWidth(block, adjustedHeight) {
    const blockWidth = block.aspectRatio * adjustedHeight;
    const blockIsTooThin = blockWidth < this.minWidth;
    const blockIsTooThick = blockWidth > this.layoutWidth;

    if (blockIsTooThin) {
      block.noAspectRatio = true;
      return (adjustedHeight * this.minWidth) / this.height;
    } else if (blockIsTooThick) {
      block.noAspectRatio = true;
      return this.layoutWidth;
    }

    return blockWidth;
  }

  _resetWorkingRow() {
    this.workingWidth = this.layoutWidth;
    this.workingRow = [];
  }

  _getPreviewPos(leftPos, width) {
    const rightPos = leftPos + width;
    const rightSpace = this.layoutWidth - rightPos;
    return leftPos > rightSpace ? 'left' : 'right';
  }

  // Scale entire row to a large height to make everything fit. OR scale row down.
  _scaleWorkingRow() {
    let currentRowHeight;
    let currentRowWidth;
    let proposedRowWidth;
    let proposedRowHeight;
    let blockWidth;
    let hideRow = false;

    let rowX = 0;
    let rowY = this.totalHeight;

    if (this.blockCount < this.stockItems.length) {
      currentRowHeight = this.height;
      currentRowWidth = this.layoutWidth - this.workingWidth;
      proposedRowWidth = this.layoutWidth;
      proposedRowHeight =
        (proposedRowWidth * currentRowHeight) / currentRowWidth;
    } else {
      if (
        this.workingWidth < 0 ||
        (this.workingWidth > 0 && this.workingWidth < this.threshold)
      ) {
        // Scale it down
        currentRowHeight = this.height;
        currentRowWidth = this.layoutWidth - this.workingWidth;
        proposedRowWidth = this.layoutWidth;
        proposedRowHeight =
          (proposedRowWidth * currentRowHeight) / currentRowWidth;
      } else {
        if (this.hideLastRow) {
          hideRow = true;
        } else {
          proposedRowHeight = +this.height;
        }
      }
    }

    if (!hideRow) {
      _.forEach(this.workingRow, (block) => {
        blockWidth = this._getBlockWidth(block, proposedRowHeight);
        this.positions[block.id] = this._getPosition(
          rowX,
          rowY,
          blockWidth,
          proposedRowHeight
        );
        rowX += blockWidth;
      });

      this.totalHeight += proposedRowHeight;
    } else {
      _.forEach(this.workingRow, (block) => {
        blockWidth = null;
        this.positions[block.id] = null;
      });
    }
  }

  _getPosition(x, y, width, height) {
    return {
      x: x,
      y: y,
      width: width,
      height: height,
      previewPos: this._getPreviewPos(x, width),
    };
  }

  _accountForDrawer() {
    const rowOfSelectedBlock = this.getContainingRow(this.selectedBlockIndex);
    // If we *just* added the row containing the selected block,
    // increase our current height to allow for the Drawer.
    if (this.drawerIsOpen && rowOfSelectedBlock === this.grid.length - 1) {
      if (this.drawerIsLoading) {
        //1.3 comes from eyeballing what looked decent from the specs
        this.totalHeight += 1.3 * this.height;
      } else {
        const drawerRows = Math.ceil(this.drawerItemCount / this.itemsPerRow);
        //140 comes from eyeballing what looked decent from the specs
        this.totalHeight += this._getDrawerOffset(drawerRows, this.height);
      }
    }
  }

  //EWE-55 Hark and Miles know this is hackish and not ideal. But we are putting all this logic in one method so it
  //can be replaced/removed later once we refactor/overhaul our Search Layout system.
  _getDrawerOffset(numRows, imageHeight) {
    const imageMargin = 10;
    const topLevelLi = 2;
    const groupWrapperMargin = 20;
    const collaspeButtonHeight = 42;
    const moreLikeThisHeight = 28;
    const drawerContentPadding = 40;

    const rowHeight = imageHeight + imageMargin + topLevelLi;
    const otherDrawerElementsHeights =
      groupWrapperMargin +
      moreLikeThisHeight +
      collaspeButtonHeight +
      drawerContentPadding;

    return numRows * rowHeight + otherDrawerElementsHeights;
  }
}

export default HorizontalLayoutHelper;

import SiteConstants from '../../../../common/SiteConstants/SiteConstants';
import LayoutBlocksSearchResults from './LayoutBlocksSearchResults';
import SimpleSearchResults from './SimpleSearchResults';

function factory() {
  return SiteConstants.getInstance().shouldUseLayoutBlocks()
    ? LayoutBlocksSearchResults
    : SimpleSearchResults;
}

const SearchResults = factory();

export default SearchResults;
